(function($, window, document) {
    "use strict";
    $(function() {
        //##Variables
        var $body = $('body'),
            $window = $(window),
            $doc = $(document),
            $hamburger = $('.hamburger'), 
            $mainNav = $('.header_top_wrap'),
            $openDropdown = $('.open_dropdown'),  
            $openDownList = $('.select-dropDownList'),  
            $open_dropdownList = $('.open_dropdownList'),
            $rightdownList = $('.right-dropDownList'),
            defaultEasing = [0.4, 0.0, 0.2, 1];
        //End Variables



         $hamburger.click(function() {
            $(this).toggleClass('toggled');
            $mainNav.fadeToggle('fast'); 
        });

        $(document).ready(function() {
            //Main slider
              $('.mainBannerSlider').owlCarousel({
                loop:true,
                navigation : false, // Show next and prev buttons
                dots : false,
                autoplay:true,
                items : 1
            })

            // TABS
           $(".tabs-menu a").click(function(event) { 
                event.preventDefault();
                $(this).parent().addClass("current");
                $(this).parent().siblings().removeClass("current");
                var tab = $(this).attr("href");
                $(".tab-content").not(tab).css("display", "none");
                $(tab).fadeIn();
            });

           //home news slider

            $('#newsSliderArea').owlCarousel({
                loop:true,
                autoplay:true,
                items : 1
            })
        });

        //selectbox dropdown

        $openDropdown.click(function() {
            $(this).toggleClass('toggled');
            $openDownList.fadeToggle('fast');
        });

        $open_dropdownList.click(function() {
            $(this).toggleClass('toggled');
            $rightdownList.fadeToggle('fast');
        });


        //Accordion

        $('.toggle').click(function(e) {
            e.preventDefault();
            var $this = $(this);
          
            if ($this.next().hasClass('show')) {
                $this.next().removeClass('show');
                $this.next().slideUp(350);
            } else {
                $this.parent().parent().find('li .inner').removeClass('show');
                $this.parent().parent().find('li .inner').slideUp(350);
                $this.next().toggleClass('show');
                $this.next().slideToggle(350);
            }
        });


        console.log("%c Developed by: Kutia Digital Agency http://kutia.net", "padding:9px 5px; background:#3c4952; line-height:25px;color: #59bf9d");
    });
}(window.jQuery, window, document));
